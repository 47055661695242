import { render, staticRenderFns } from "./Posts.vue?vue&type=template&id=ee5eefe2&scoped=true"
import script from "./Posts.vue?vue&type=script&lang=js"
export * from "./Posts.vue?vue&type=script&lang=js"
import style0 from "@/assets/scss/components/globals/posts.scss?vue&type=style&index=0&id=ee5eefe2&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee5eefe2",
  null
  
)

export default component.exports