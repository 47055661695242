<!-- eslint-disable vue/no-v-html -->
<template>
  <v-card
    rounded="lg"
    elevation="0"
    :class="`mb-6 posts-card shadow-${type}`"
  >
    <div class="posts-card-user">
      <div class="posts-user">
        <avatar :url="post.user.photo_url" />
      </div>
      <span
        :class="`user-name user-name-${type}`"
      >
        {{ post.user.pretty_name }}
      </span>
      <edit-post
        :post-id="post.id"
        :enable-delete-btn="currentUser.id == post.user.id"
        :show-todo-btn="post.category === 'improve'"
        :show-done-btn="post.category === 'todo'"
        :post-category="post.category"
        @showPopUp="showPopUp"
      />
    </div>
    <div
      class="card-text"
      v-html="setLinks(post.text)"
    />
    <pop-up-delete
      ref="popupRef"
      @doDelete="deletePost(post)"
    />
    <v-row
      align="center"
      class="row"
    >
      <div class="posts-actions">
        <div class="mute-icon">
          <v-icon
            v-if="dontWantToTalk"
            color="red"
          >
            mdi-volume-mute
          </v-icon>
        </div>

        <div class="interactions">
          <v-img
            class="posts-icon"
            src="@/assets/img/comment.svg"
            max-width="48"
            max-height="48"
            @click="comment"
          />
          <span class="posts-icon-text">0</span>

          <v-img
            class="posts-icon"
            :src="likeIcon()"
            max-width="48"
            max-height="48"
            @click="like(post.id)"
          />
          <span class="posts-icon-text"> {{ post.likes_count }} </span>
        </div>
      </div>
    </v-row>
    <post-comments
      ref="comments"
      :column-type="type"
      :current-user="currentUser"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import trackEvent from 'mixpanel-browser';
import { logEvent } from '../../plugins/firebase';
import { SetAsLink } from '../../util/linkValidator';

export default {
  name: 'Posts',

  components: {
    Avatar: () => import('@/components/globals/Avatar.vue'),
    EditPost: () => import('@/components/globals/EditPost.vue'),
    PopUpDelete: () => import('@/components/globals/PopUpDelete.vue'),
    PostComments: () => import('@/components/globals/PostComments.vue'),
  },

  props: {
    type: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    postKind: {
      type: String,
      default: '',
    },
    post: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      hasLiked: this.post.user_liked,
      hasClicked: false,
    };
  },

  computed: {
    ...mapGetters([
      'getLikeSuccess',
      'currentUser',
    ]),

    dontWantToTalk() {
      return this.post.talk;
    },
  },

  watch: {
    getLikeSuccess(value) {
      if (value) {
        this.$emit('reloadPosts');
      }
    },
  },

  methods: {
    ...mapActions([
      'createLike',
      'destroyLike',
      'deletePost',
    ]),
    translateCategory(category) {
      if (category === 'todo') return 'Tarefas';
      return category === 'improve' ? 'Melhorar' : 'Bom';
    },
    setLinks(text) {
      const newtext = SetAsLink(text);

      return newtext;
    },

    like(id) {
      if (this.hasLiked) {
        this.destroyLike({ id });
      } else {
        this.createLike({ id });
      }
      this.hasLiked = !this.hasLiked;
    },

    likeIcon() {
      if (this.hasLiked) {
        // eslint-disable-next-line global-require
        return require('@/assets/img/like-green.svg');
      }
      // eslint-disable-next-line global-require
      return require('@/assets/img/like.svg');
    },
    showPopUp() {
      this.$refs.popupRef.show();
    },
    comment() {
      this.$refs.comments.show();

      if (!this.hasClicked) {
        this.hasClicked = true;
        logEvent('clicou_comentario', { coluna: this.type });
        const category = this.translateCategory(this.type);
        trackEvent.track('clicou_comentario', { Categoria: category });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/scss/components/globals/posts.scss"/>
